// src/components/ImageWithCallouts.tsx
import React from 'react';
import Callout from './callout.component';
import { Box, Chip, IconButton, Paper, Typography } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";

interface CalloutData {
  text: string;
  position: { left: string; top: string };
}

interface ImageWithCalloutsProps {
  src: string;
  callouts: CalloutData[];
}

const ImageWithCallouts: React.FC<ImageWithCalloutsProps> = ({ src, callouts }) => {
  return (
    <Box style={{ textAlign:'center'}} position="relative" display="inline-block">
      <img src={src} alt="Annotated" style={{ textAlign:'center',width: '50%', height: 'auto' }} />
      {callouts.map((callout, index) => (
        <Callout key={index} position={callout.position} title={callout.text}>
            
                <IconButton color="info"><InfoIcon/></IconButton>
        {/* <Chip label={callout.text} color="primary"/> */}
 
          {/* <Paper sx={{ padding: 1 }}> */}
            {/* <Typography fontSize={10}>{callout.text}</Typography> */}
          {/* </Paper> */}

        </Callout>
      ))}
    </Box>
  );
};

export default ImageWithCallouts;