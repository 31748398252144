import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Button,
  FormHelperText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid2";
import "./contact.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Divider from "@mui/material/Divider";
import { FormControl } from "@mui/material";
import emailjs from "@emailjs/browser";

const ContactComponent = () => {
  useEffect(() => {
    emailjs.init({ publicKey: "SM-R89ml84yu_u8-E" }); // Replace with your actual public key
  }, []);
  const [alertOpen, setAlertOpen] = useState(false);
  const [response, setResponse] = useState(false);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const sendEmail = (e: any) => {

   emailjs.send('service_fs04r59', 'template_g6p8z7p', e).then(
       (response) => {
       setResponse(true);
       setAlertOpen(true);

       },
       (error) => {
         setResponse(false);
       setAlertOpen(true);

      },
    );

  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (name === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (message === "") {
      setMessageError(true);
    } else {
      setMessageError(false);
    }

    if (email === "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (name !== "" && message !== "" && email !== "") {
      sendEmail({
        name: name,
        phoneNumber: phoneNumber,
        message: message,
        email: email,
      });
    }
  };
  return (
    <div>
      <Box
        style={{ width: "80%", marginRight: "auto", marginLeft: "auto" }}
        sx={{}}
      >
        <Typography style={{ marginTop: 10 }} variant="h4">
          Clement Electronics
        </Typography>
        <Divider />
        <br />
        <Link to="/">
          <Button variant="outlined">
            <ArrowBackIcon />
            Back
          </Button>
        </Link>

        {response ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={alertOpen}
            autoHideDuration={6000}
            onClose={() => {
              setAlertOpen(false);
            }}
          >
            <Alert
              onClose={() => {
                setAlertOpen(false);
              }}
              severity="success"
            >
              Message sent! Will get back to you soon. Thank you.
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={alertOpen}
            autoHideDuration={6000}
            onClose={() => {
              setAlertOpen(false);
            }}
          >
            <Alert
              onClose={() => {
                setAlertOpen(false);
              }}
              severity="error"
            >
              Error! Unable to send message, please try again later.
            </Alert>
          </Snackbar>
        )}
        <Card
          variant="outlined"
          style={{
            border: "none",
            width: "50vw",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <CardContent>
       
              <Box
                component="form"
                sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                noValidate
                autoComplete="off"
              >
                <Typography variant="h5">Contact</Typography>

                <Grid container spacing={2}>
                  <Grid size={6}>
                    <FormControl fullWidth required error={nameError}>
                      <TextField
                      required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        style={{ width: "100%" }}
                        id="outlined-required"
                        label="Name"
                      />
                      {nameError ? (
                        <FormHelperText id="component-helper-text">
                          This field is required
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid size={6}>
                    <FormControl fullWidth required error={emailError}>
                      <TextField
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        style={{ width: "100%" }}
                        required
                        id="outlined-required"
                        label="Email"
                      />
                      {emailError ? (
                        <FormHelperText id="component-helper-text">
                          This field is required
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>

                <TextField
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  style={{ width: "100%" }}
                  id="outlined-required"
                  label="Phone Number"
                />
                <br />
                <FormControl fullWidth required error={messageError}>
                  <TextField
                    required
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    style={{ width: "100%" }}
                    id="outlined-multiline-flexible"
                    label="Message"
                    multiline
                    rows={4}
                  />
                  {messageError ? (
                    <FormHelperText id="component-helper-text">
                      This field is required
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <Grid style={{ marginLeft: "93%" }} container>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Send
                  </Button>
                </Grid>
              </Box>
        
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default ContactComponent;
