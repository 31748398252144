// src/components/Callout.tsx
import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

interface CalloutProps extends TooltipProps {
  position: { left: string; top: string };
}

const Callout: React.FC<CalloutProps> = ({ position, children, ...tooltipProps }) => {
  return (
    <Tooltip
      {...tooltipProps}
      style={{ position: 'absolute', left: position.left, top: position.top }}
    >
      {children}
    </Tooltip>
  );
};

export default Callout;