import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import "./home.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ImageWithCallouts from "./imagewithcallouts.component";
import Grid from "@mui/material/Grid2";
import {
  Collapse,
  Paper,
  Popover,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const HomeComponent = () => {
  const callouts = [
    {
      text: "Stereo 1/4 inch Analog Outputs",
      position: { left: "36.6%", top: "0%" },
    },
    {
      text: "Stereo 1/4 inch Analog Inputs",
      position: { left: "52.6%", top: "0%" },
    },
    {
      text: "High headroom, low noise amps. Limiting low noise input amps.",
      position: { left: "45%", top: "35%" },
    },
    {
      text: "Analog output gain controls",
      position: { left: "31.6%", top: "27%" },
    },
    { text:  "SPI Access", position: { left: "57%", top: "68%" } },
    { text: "2 programmable LEDs", position: { left: "29.5%", top: "89%" } },
    {
      text: "DIN opto-couple MIDI input",
      position: { left: "22%", top: "55%" },
    },
    {
      text: "ZIF socket daisy seed compatible",
      position: { left: "44%", top: "68%" },
    },
    {
      text: "7 A/D input parameter controls",
      position: { left: "46.6%", top: "92%" },
    },
    {
      text: "2 programmable push buttons",
      position: { left: "65%", top: "69%" },
    },
    { text:  "I2C access for LCD", position: { left: "45%", top: "58%" } },
  ];

  const images = ["image1.jpg", "image2.jpg", "image3.jpg", "image4.jpg"];
  const descriptions = [
    "Stereo 1/4 inch Analog Outputs",
    "Stereo 1/4 inch Analog Inputs",
    "High headroom, low noise amps",
    "Limiting low noise input amps",
    "Analog output gain controls",
    "I2C access for LCD",
    "2 programmable LEDs",
    "DIN opto-couple MIDI input",
    "ZIF socket daisy seed compatible",
    "7 A/D input parameter controls",
    "2 programmable push buttons",
    "SPI Access",
  ];

  const [selectedImage, setImage] = useState("image1.jpg");
  const [popoverVisibility, setPopoverVisible] = useState(false);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "10px", transform: "scale(2)" }}
    >
      •
    </Box>
  );
  const StrikethroughTypography = styled(Typography)(({ theme }) => ({
    textDecoration: 'line-through',
    color: theme.palette.text.secondary, // Adjust color as needed
  }));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.only("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.only("lg"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.only("xl"));

  // $grid-breakpoints: (
  //   xs: 0,
  //   sm: 576px,
  //   md: 768px,
  //   lg: 992px,
  //   xl: 1200px,
  //   xxl: 1400px
  // );

  return (
    <div>
      <Box sx={{ width: "95%", margin: "0 auto" }}>
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Clement Electronics
        </Typography>
        <Divider sx={{ marginY: 2 }} />
      </Box>

      <Grid spacing={10} container>
        <Grid
          size={6}
          style={{
            textAlign: "right",
          }}
        >
          <img
            style={{ marginRight: "5%", width: "50%", maxWidth: 533 }}
            src={require(`../images/${selectedImage}`)}
            alt="Selected"
          />

          <Grid>
            {images.map((image) => (
              <Button
                key={image}
                onClick={() => setImage(image)}
                sx={{ margin: 1 }}
              >
                <img
                  style={{ width: 100, height: 100 }}
                  alt=""
                  src={require(`../images/${image}`)}
                />
              </Button>
            ))}
          </Grid>
        </Grid>

        <Grid size={6}>
          <Typography variant="h4">Seed Starter</Typography>
          <Typography variant="subtitle1">
            Loose the wires, start smarter.
          </Typography>
          <Typography
            color="textSecondary"
            lineHeight={2}
            variant="body2"
            sx={{ width: "60%", marginY: 2 }}
          >
            Every project needs a clean start! The <b>Seed Starter</b> is a
            development platform designed to be compatible with the daisy seed
            microcontroller. <b>Seed Starter</b> makes developing audio related
            projects easier.
            <br />
            The platform is feature packed, including 1/4 inch stereo input, and
            outputs, MIDI IN, 7 A/D input parameter controls, programmable push
            buttons, and LEDs!
            <br />
            Everything you need to start sprouting new projects!
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            sx={{ marginY: 1 }}
          >
            Features:
          </Typography>

          {descriptions.map((descriptor, index) => (
            <Typography
              key={index}
              variant="body2"
              color=" #999;"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                marginY: 0.5,
              }}
            >
              {bull} {descriptor}
            </Typography>
          ))}
          <span style={{display:"flex"}}>
                         
      <StrikethroughTypography style={{marginTop:5}} variant="subtitle1">$285.00</StrikethroughTypography>
      <Typography variant="h5" color="primary">
        $190.00   
      </Typography>
      <Typography style={{marginLeft:2, bottom:0,marginTop:5}} color="#1677ff" variant="subtitle1">CAD</Typography> 
                          {/* <Typography color="#1677ff" variant="h5">$285.00</Typography> */}
                          {/* <Typography style={{marginLeft:2, bottom:0,marginTop:5}} color="#1677ff" variant="subtitle1">CAD</Typography> */}
                          </span>

          <Box sx={{ marginLeft: "20%", marginTop: 2 }}>
            <Link to="/Contact">
            <Button
         
              variant="contained"
            >
              Contact Now
            </Button>
      

            </Link>
          </Box>
        </Grid>
      </Grid>
      <Divider
        sx={{
          width: "50%",
          marginRight: "auto",
          marginLeft: "auto",
          marginY: 2,
        }}
      />
      <div style={{ display: "flex" }}>
        <iframe
          style={{ border: "none", marginRight: "auto", marginLeft: "auto" }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5TJhNhgH_f0?si=vu5RF3zOGvOKR3rg"
          title="YouTube video player"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          //  referrerpolicy="strict-origin-when-cross-origin"
        />
      </div>
      <Divider
        sx={{
          width: "50%",
          marginRight: "auto",
          marginLeft: "auto",
          marginY: 2,
        }}
      />

      <Grid style={{ textAlign: "center" }}>
        <ImageWithCallouts
          src={require("../images/image5.jpg")}
          callouts={callouts}
        />
      </Grid>
    </div>
  );
};

export default HomeComponent;
