import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeComponent from './home/home.component';
import ContactComponent from './contact/contact.component';

// function App() {
   
//   // return <HomeComponent />;
//   return <ContactComponent />;
// }

// export default App;

 function App() {
  return (
    <BrowserRouter>
      <Routes>

      <Route path="/" element={<HomeComponent/>}/>
      <Route path="Contact" element={      <ContactComponent />}/>

 
   
          {/* <Route path="*" element={<NoPage />} /> */}
       
      </Routes>
    </BrowserRouter>
  );
}
export default App;
